import React from "react";
import top__solpeepz from "../assets/newSolpeepz.svg";
import madfire from "../assets/madfire.png";
import top__discord from "../assets/button_1.png";
import "./Header.css";

function Header() {
  return (
    <>
      <div className="header">
        <div className="topbar">
          <div className="topbar__body">
            <div className="madfire">
              <img className="madfireImg" src={madfire} alt="" />
            </div>
            <div className="centerText">
              <img className="top__solpeepz" src={top__solpeepz} alt="" />
            </div>
            {/* <div className="top__discord">
            <a href="https://t.co/DKYXf6xZd2">
              <img className="top__discordImg" src={top__discord} alt="" />
              </a>
              <div className="invite1">
               DISCORD INVITE
              </div>
            </div> */}
            <a className="top__discord" href="https://twitter.com/SOlPeepzNFT">
              <div className="nav__disc">@SolPeepzNFT</div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
